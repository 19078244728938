<template>
  <div class="main clearfix">
    <!-- <div class="m_nav">
      <div class="m_nav_box">
          <ul class="nav-box">
              <li><router-link to="/news/company">公司新闻</router-link></li>
              <li><router-link to="/news/publish">通知公告</router-link></li>
          </ul>
      </div>
    </div> -->
    <CurrentLocation />
    <div class="box">
      <div class="ct2-sd">
      <PanelNav :title="title" :navs="navs" />
      </div>
    <div class="ct2-mn">
      <PublishList :more="true" :data="data" />
    </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import CurrentLocation from "../layout/components/CurrentLocation";
import PanelNav from "../layout/components/PanelNav";
import PanelLast from "../layout/components/PanelLast";
// import PanelList from "../layout/components/PanelList";
import PublishList from "../layout/components/PublishList";

import axios from "@/api/apis";

export default {
  name: "Publish",
  components: { CurrentLocation, PanelNav, PanelLast, PublishList },
  created() {},
  setup() {
    const state = reactive({
      title: "公司建设",
      navs: [
        {
          title: "公司新闻",
          router: "/news/company",
        },
        {
          title: "通知公告",
          router: "/news/publish",
        },
        {
          title: "党风建设",
          router: "/party",
          data: {
            list: [],
          },
        },
      ],
      data: {
        currentPage: "",
        size: "",
        total: "",
        list: [],
      },
    });
    const loadArticle = () => {
      axios
        .post("https://cqgdrm.com/api/website/publish/page", { group: 1, pageSize: 5, current: 1 })
        .then(function (data) {
          state.data.currentPage = data.currentPage;
          state.data.size = data.size;
          state.data.total = data.total;

          data.data.forEach((item) => {
            var image=''
            if(item.images!= null){
            image=item.images[0]
          }
          else{
            image=null
          }
            state.data.list.push({
              id: item.id,
              title: item.title,
              type: 1,
              abstracts:item.abstracts,
              content: item.content,
              images:image,
              createdTime: item.createdTime,
              router: "/news/publish/details",
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadArticle();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
</style>