<template>
<div>
  <div v-if="!more" class="fengmian-tit">
    <router-link :to="router" class="more">更多</router-link>
    <span>{{ title }}</span>
  </div>
  <div class=" bordernone">
    <ul class="list-3">
      <li
        v-for="(item, index) in data.list"
        :key="index"
        :data-index="index"
        :title="item.title"
        :class="[more && index % 5 == 4 ? `line` : ``]"
      >
        <router-link
          v-if="item.type == 1"
          :to="{
            path: item.router,
            query: { id: item.id },
          }"
          :title="item.title"
        >
          <div v-if="item.images==null">
              <a class="title-t-pub">{{ item.title }}</a>
              <span>{{ dateFormat(item.createdTime, "YYYY-MM-DD") }}</span>
            </div>
            <div v-if="item.images!==null">
              <div class="img-p">
               <a><img 
              :src="item.images" alt="">
              </a>
            </div>
            <div style="display:flex; flex-direction:column; justify-content: space-between;">
              <div style="flex: 1;">
                <a class="title-p">{{ item.title }}</a>
              </div>
            </div>
              <span>{{ dateFormat(item.createdTime, "YYYY-MM-DD") }}</span>
            </div>
        </router-link>

      </li>
    </ul>
  </div>
  <div v-if="more" class="pages">
    <ul>
      <li>
        <span class="pageinfo"
          >共 <strong>{{ data.currentPage }}</strong
          >页<strong>{{ data.total }}</strong
          >条记录</span
        >
      </li>
    </ul>
  </div>
    </div>
</template>

<script>
import { toRefs } from "vue";
import dateFormat from "@/utils/dateFormat";
import {clamp} from "@/utils/clamp";

export default {
  name: "PublishList",
  components: {},
  props: ["title", "router", "more", "data"],
  data(){
    return {
            expande: true,
            needShowExpande: false,
    }
  },
  methods: {
        expandeClick() {
            console.log('expandeClick')
            this.expande = !this.expande
        },
    },
  setup(props) {
    return { dateFormat, ...toRefs(props) };
  },
   mounted() {
        this.$nextTick(() => {
            let lineHeight = 22
            if (this.$refs.content.offsetHeight > lineHeight * 3) {
                this.expande = false
                this.needShowExpande = true
            } else {
                this.expande = true
            }
        })
    },

};
// console.log(item)
</script>

<style scoped>
.title-describes-tp{
  display: block;
  width: 850px;
  height: 40px;
  line-height: 30px;
  display: block;
  float: left;
  color: #5b5b5b;
  word-wrap:break-word;
  white-space:normal;
}
.title-t-pub{
   width: 800px;
   float: left;
   color: #000;
   font-size: 18px;
   overflow:hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   -o-text-overflow:ellipsis;
}
</style>
